
import {Component, Vue, Prop} from 'vue-property-decorator';
import {IMissionDB, LabelValue, WorkerMission} from "@/types";
import {Getter} from "vuex-class";
import {capitalize} from "@/helpers/commons";

@Component({
    name: "PresentationWorker"
})
export default class PresentationWorker extends Vue {
    @Prop() mission!: IMissionDB;
    @Prop() worker!: WorkerMission;
    @Getter('getJobOption') getJobOption!: any;
    @Getter('userContext') userContext!: string;

    workerName(worker: WorkerMission) {
        return `${capitalize(worker.firstname)} ${capitalize(worker.lastname)}`;
    }

    workerJob(worker: WorkerMission) {
        if (this.mission?.positions?.length) {
            const jobId = this.mission.positions[worker.positionIndex]?.jobId;

            if (jobId) {
                const job:any = this.getJobOption(jobId);

                if (job) {
                    return capitalize(job.label);
                }
            }
        }
    }

    workerStatus(worker: WorkerMission) {
        return this.$t('mission.workerStatus.' + worker.status + "." + this.userContext);
    }
}
