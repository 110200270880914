
import {Component, Vue, Prop} from 'vue-property-decorator';
import {IInterimDB, IJobDB, IMissionDB, LabelValue, StoreAction, WorkerMission} from "@/types";
import {Action, Getter} from "vuex-class";
import {capitalize} from "@/helpers/commons";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
import {ROUTES} from "@/data";

@Component({
    name: "InterimPublic",
    components: {
        VuePdfApp
    }
})
export default class InterimPublic extends Vue {
    @Prop() interim!: IInterimDB;
    @Prop() mission!: IMissionDB;
    @Prop() missionJobId!: string;

    @Getter('getJobOption') getJobOption!: any;
    @Getter('getJob') getJob!: (jobId:string) => IJobDB;
    @Action('actions/sendPresentationCommand') sendPresentationCommand!: StoreAction;

    loading = false;
    showCV = false;
    apiUrl:string = <string>process.env.VUE_APP_API_STATIC_URL + "/" ;

    cvConfig = {
        toolbar: {
            toolbarViewerRight: false,
            toolbarViewerLeft: {
                findbar: false
            }
        },
        secondaryToolbar: false
    }

    get isPresentation() {
        return this.$route.name === ROUTES.PRESENTATION;
    }

    get fullName() {
        return capitalize(this.interim.firstname) + " " + capitalize(this.interim.lastname);
    }

    get cvPath() {
        if (this.interim?.publicResume) {
            return this.apiUrl + this.interim.publicResume;
        }
    }

    get isCV() {
        return this.interim?.publicResume?.endsWith('.pdf');
    }

    get interimAddress() {
        return this.interim?.address?.city;
    }

    openCv() {
        this.showCV = true;
    }

    getCertificationName(jobId: string, certId: string) {
        let j:any = this.getJob(jobId);

        if (j) {
            let c = (j.certifications || []).find((cert:any) => cert._id === certId);

            if (c) {
                return c.name;
            }
        }
        return "";
    }
}
