
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {IInterimDB, IMissionDB, StoreAction, WorkerMission} from "@/types";
import {capitalize, toastError} from "@/helpers/commons";
import {MissionCommand, ROUTES, WorkerMissionStatus} from "@/data";
import PresentationWorker from "@/components/Mission/PresentationWorker.vue";
import InterimPublic from "@/components/interim/InterimPublic.vue";
import clonedeep from "lodash.clonedeep";
import {Action, Getter} from "vuex-class";
import {showModal} from "@/helpers/callables";
import CompanyReject from "@/components/modal/CompanyReject.vue";

@Component({
    name: "MissionPresentation",
    components: {CompanyReject, InterimPublic, PresentationWorker},
})
export default class MissionPresentation extends Vue {
    @Prop(Object) readonly mission!: IMissionDB;
    @Getter('getJobOption') getJobOption!: any;
    @Action('actions/loadInterim') loadInterim!: StoreAction;
    @Action('actions/sendCommandMission') sendCommandMission!: StoreAction;
    @Action('actions/sendPresentationCommand') sendPresentationCommand!: StoreAction;

    showCompanyReject = false
    loading = false;
    capitalize = capitalize;
    interim: IInterimDB | null = null;
    interims: IInterimDB[] = [];
    selectedWorker:WorkerMission | null = null;

    errors: any = {};
    globalErrors:any = [];

    @Watch('selectedWorker')
    onSelectedWorker() {
        if (this.selectedWorker) {
            if (this.isPresentation) {
                // @ts-ignore;
                this.interim = this.selectedWorker;
            } else {
                this.fetchInterim();
            }
        } else {
            this.interim = null;
        }
    }

    get token() {
        return this.$route.params.token;
    }

    get isPresentation() {
        return this.$route.name === ROUTES.PRESENTATION;
    }

    get workers() {
        return (this.mission?.workers || []).map((w:any) => {
            if (!w.workerId && w._id) {
                w.workerId = w._id;
            }
            return w;
        }).sort((a: WorkerMission, b: WorkerMission) => {
            if (a.status === b.status) {
                return 0;
            }
            if ((a.status === WorkerMissionStatus.presented && b.status !== WorkerMissionStatus.presented)
                || (a.status === WorkerMissionStatus.validated && b.status !== WorkerMissionStatus.presented)) {
                return -1;
            }
            return 1;
        });
    }

    get missionJobId() {
        if (this.selectedWorker && this.mission?.positions?.length) {
            const jobId = this.mission.positions[this.selectedWorker.positionIndex]?.jobId;

            if (jobId) {
                return jobId;
            }
        }
    }

    selectWorker(worker:WorkerMission) {
        if (worker.workerId !== this.selectedWorker?.workerId) {
            this.selectedWorker = null;
            this.$nextTick(() => {
                this.selectedWorker = worker;
                this.$forceUpdate();
            });
        }
    }

    async fetchInterim() {
        if (this.selectedWorker) {
            // @ts-ignore;
            const fetched:any = this.interims.find((i:any) => i._id === (this.selectedWorker?._id || this.selectedWorker?.workerId));

            if (fetched) {
                this.interim = clonedeep(fetched);
            } else {
                this.loading = true;
                try {
                    this.interim = await this.loadInterim({
                        interimId: this.selectedWorker.workerId,
                        query: {
                            missionId: this.mission._id
                        }
                    });
                    // @ts-ignore;
                    this.interim.presentationMessage = this.selectedWorker.presentationMessage;
                    // @ts-ignore;
                    this.interim.status = this.selectedWorker.status;
                } catch (e) {
                    console.log(e);
                    this.errors = e.errors;
                    this.globalErrors = e._global;
                }
                this.loading = false;
            }
        }
    }

    async sendCommand(command:string, data?:any) {
        try {
            const result = await this.sendCommandMission({
                missionId: this.mission?._id,
                command,
                data
            });
            this.$emit('command', result);
            this.selectedWorker = null;
        } catch (e) {
            console.log(e);
            // @ts-ignore;
            this.$toast.open({
                message: toastError(e),
                type: "error",
                duration: 10000
            });
        }
    }

    async presentationCommand(command:string, data?:any) {
        try {
            const result = await this.sendPresentationCommand({
                command,
                data
            });
            this.$emit('command', result);
            this.selectedWorker = null;
         } catch (e) {
            console.log(e);
        }
    }

    validate() {
        if (this.isPresentation) {
            // @ts-ignore;
            this.presentationCommand(MissionCommand.workerValidate, {workerId: this.isPresentation ? this.selectedWorker._id : this.selectedWorker.workerId, presentationToken: this.token})
        } else {
            // @ts-ignore;
            this.sendCommand(MissionCommand.workerValidate, {workerId: this.isPresentation ? this.selectedWorker._id : this.selectedWorker.workerId});
        }
    }

    refuse() {
        showModal.call(this, 'CompanyReject');
    }

    confirmRefuse(data:any) {
        if (this.isPresentation) {
            // @ts-ignore;
            this.presentationCommand(MissionCommand.workerCompanyReject, {workerId: this.isPresentation ? this.selectedWorker._id : this.selectedWorker.workerId, presentationToken: this.token, rejectedReason: data.reason})
        } else {
            // @ts-ignore;
            this.sendCommand(MissionCommand.workerCompanyReject, {workerId: this.isPresentation ? this.selectedWorker._id : this.selectedWorker.workerId, rejectedReason: data.reason});
        }
        this.$modal.hide('CompanyReject');
    }

    beforeMount() {
        if (this.workers?.length) {
            this.selectedWorker = this.workers[0];
        }
    }
}
